var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "CompanyTariffModal",
        attrs: {
          size: _vm.SIZES.small,
          header: { isClosable: true },
          title: `${_vm.isEdit ? "Edit" : "Add new"} tariff`,
          "data-test-id": "company-tariff-form",
        },
        scopedSlots: _vm._u([
          {
            key: "alerts",
            fn: function () {
              return [
                _c("StoreNotificationComponent", {
                  attrs: {
                    "store-domain": _vm.DOMAINS_MODEL.crm.company,
                    scope: _vm.COMPANY_SCOPES.companyTariffForm,
                    action: _vm.isEdit ? "Save" : "Add",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "MuiValidationWrapper",
                  {
                    attrs: {
                      "extra-conditions": [
                        !_vm.isEqual(_vm.originalInputs, _vm.inputs),
                      ],
                    },
                    on: {
                      areAllValid: (isValid) => (_vm.isFormValid = isValid),
                    },
                  },
                  [
                    !_vm.isEdit && _vm.hasAnActiveTariff
                      ? _c("div", { staticClass: "mb-4" }, [
                          _vm._v(
                            " You have another tariff, will finish on " +
                              _vm._s(
                                _vm.reformatDateTime(
                                  _vm.get(_vm.currentTariff, "end"),
                                  _vm.DATE_FORMAT.dob
                                )
                              ) +
                              ", your new tariff must start after this date. "
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "mb-4" },
                      [
                        _c("ContentCellComponent", {
                          attrs: {
                            value: _vm.isEdit
                              ? _vm.sentenceCase(
                                  _vm.get(_vm.tariffToEdit, "fleet")
                                )
                              : _vm.sentenceCase(_vm.tariffEditFleet),
                            label: "Fleet",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.isEdit
                      ? _c("ContentCellComponent", {
                          staticClass: "mb-4",
                          attrs: {
                            value: _vm.get(_vm.tariffToEdit, "tariffName"),
                            label: "Tariff name",
                          },
                        })
                      : _c(
                          "div",
                          { staticClass: "mb-4" },
                          [
                            _vm.availableCompanyTariffsStatus.LOADING
                              ? _c("ui-skeleton", {
                                  attrs: { "data-test-id": "select-skeleton" },
                                })
                              : _c("MuiSelect", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: {
                                        isRequired: true,
                                      },
                                      expression:
                                        "{\n            isRequired: true,\n          }",
                                    },
                                  ],
                                  staticClass: "w-100",
                                  attrs: {
                                    options:
                                      _vm.getAvailableCompanyTariffOptions(),
                                    label: "Tariff name*",
                                    name: "tariffName",
                                    placeholder: "Select a tariff",
                                    "data-test-id": "tariff-select",
                                  },
                                  on: {
                                    change: (tariffUuid) =>
                                      (_vm.tariffSelected = _vm.find(
                                        _vm.availableCompanyTariffs,
                                        { uuid: tariffUuid }
                                      )),
                                  },
                                  model: {
                                    value: _vm.inputs.tariffUuid,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.inputs, "tariffUuid", $$v)
                                    },
                                    expression: "inputs.tariffUuid",
                                  },
                                }),
                          ],
                          1
                        ),
                    _vm.hasTariffQuotas
                      ? _c(
                          "div",
                          { staticClass: "mb-4" },
                          [
                            _c("MuiSelect", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    isRequired: true,
                                  },
                                  expression:
                                    "{\n            isRequired: true,\n          }",
                                },
                              ],
                              staticClass: "w-100 text-capitalize",
                              attrs: {
                                options: _vm.map(
                                  _vm.availableCompanyTariffPeriods,
                                  (period) => ({
                                    label: `${period.period} - ${period.fee}`,
                                    value: period.period,
                                  })
                                ),
                                label: "Periodicity*",
                                name: "periodicity",
                                placeholder: "Select",
                                "data-test-id": "periodicity-select",
                              },
                              model: {
                                value: _vm.inputs.period,
                                callback: function ($$v) {
                                  _vm.$set(_vm.inputs, "period", $$v)
                                },
                                expression: "inputs.period",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isEdit
                      ? _c("ContentCellComponent", {
                          staticClass: "mb-4",
                          attrs: {
                            value: _vm.reformatDateTime(
                              _vm.inputs.startDate,
                              _vm.DATE_FORMAT.dob
                            ),
                            label: "Start date",
                          },
                        })
                      : _c(
                          "div",
                          { staticClass: "mb-4" },
                          [
                            _c("MuiDatePicker", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    isRequired: true,
                                    isValidDate:
                                      _vm.DATE_INPUT_VALIDATIONS.validDate,
                                    isBeforeCurrentTariff:
                                      _vm.isBeforeCurrentTariff,
                                  },
                                  expression:
                                    "{\n            isRequired: true,\n            isValidDate: DATE_INPUT_VALIDATIONS.validDate,\n            isBeforeCurrentTariff,\n          }",
                                },
                              ],
                              staticClass: "w-100",
                              attrs: {
                                "valid-date-range": _vm.validStartRange,
                                "date-format-key": _vm.DATE_FORMAT_KEYS.dob,
                                placeholder: _vm.DATE_FORMAT.dob,
                                "skip-time-step": "",
                                "data-test-id": "start_date-select",
                                label: "Start date*",
                                name: "startDate",
                              },
                              on: { "update:date": _vm.setStartDate },
                              model: {
                                value: _vm.inputs.startDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.inputs, "startDate", $$v)
                                },
                                expression: "inputs.startDate",
                              },
                            }),
                          ],
                          1
                        ),
                    _c("MuiDatePicker", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            isRequired: true,
                            isValidDate: _vm.DATE_INPUT_VALIDATIONS.validDate,
                          },
                          expression:
                            "{\n          isRequired: true,\n          isValidDate: DATE_INPUT_VALIDATIONS.validDate,\n        }",
                        },
                      ],
                      staticClass: "w-100",
                      attrs: {
                        "valid-date-range": {
                          start: _vm.minimumStartForEndDate,
                          end: _vm
                            .moment(_vm.minStartDate)
                            .year(2100)
                            .endOf("year"),
                        },
                        "date-format-key": _vm.DATE_FORMAT_KEYS.dob,
                        placeholder: _vm.DATE_FORMAT.dob,
                        "skip-time-step": "",
                        "data-test-id": "end_date-select",
                        label: "End date*",
                        name: "endDate",
                      },
                      on: {
                        "update:date": (newDate) =>
                          (_vm.inputs.endDate = newDate),
                      },
                      model: {
                        value: _vm.inputs.endDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "endDate", $$v)
                        },
                        expression: "inputs.endDate",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-center justify-content-sm-end align-items-center",
                  },
                  [
                    _c("CancelButton", {
                      on: {
                        click: function ($event) {
                          return _vm.$emit("closeModal")
                        },
                      },
                    }),
                    _c(
                      "ui-button",
                      {
                        staticClass: "wmin-initial",
                        attrs: {
                          disabled:
                            !_vm.isFormValid ||
                            _vm.companyTariffFormStatus.LOADING ||
                            _vm.availableCompanyTariffPeriodsStatus.LOADING ||
                            _vm.availableCompanyTariffsStatus.LOADING,
                          loading: _vm.companyTariffFormStatus.LOADING,
                        },
                        on: { clickbutton: _vm.companyTariffRequest },
                      },
                      [_vm._v(" " + _vm._s(_vm.isEdit ? "Save" : "Add") + " ")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }