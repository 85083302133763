<script>
import each from 'lodash/each';
import clone from 'lodash/clone';
import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import values from 'lodash/values';
import { mapActions, mapState } from 'vuex';
import { COMPANY_SCOPES } from '@domains/CRM/Companies/store/CompanyModule';
import { PROFILES_TYPES } from '@domains/CRM/Users/constants/userStatus';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { Cancel as CancelButtonAttrs } from '@/constants/modalButtons.const';
import {
  GenericModalComponent,
  StoreNotificationComponent,
} from '@/components';
import { EMPLOYEE_INPUT_FORM_KEYS, FLEETS } from './const/CompanyTariffs';

export default {
  name: 'CompanyEmployeeTariffModal',
  components: {
    GenericModalComponent,
    StoreNotificationComponent,
  },
  props: {
    fleet: {
      type: String,
      required: true,
      validator: value => includes(values(FLEETS), value),
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      inputs: {
        [`${FLEETS.open}${EMPLOYEE_INPUT_FORM_KEYS.uuid}`]: null,
        [`${FLEETS.open}${EMPLOYEE_INPUT_FORM_KEYS.isMigrated}`]: false,
        [`${FLEETS.dedicated}${EMPLOYEE_INPUT_FORM_KEYS.uuid}`]: null,
        [`${FLEETS.dedicated}${EMPLOYEE_INPUT_FORM_KEYS.isMigrated}`]: false,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state[COMPANY_SCOPES.company].data,
      companyEmployeeTariffStatus: state => state[COMPANY_SCOPES.employeeTariffForm].STATUS,
      availableCompanyTariffs: state => get(state[COMPANY_SCOPES.availableCompanyTariffs].data, 'data', []),
      availableCompanyTariffsStatus: state => state[COMPANY_SCOPES.availableCompanyTariffs].STATUS,
      dedicatedPersonalTariffUuid: state => get(state[COMPANY_SCOPES.companyTariffs].data, 'data.dedicatedPersonalTariff.tariffUuid'),
      dedicatedPersonalIsMigrated: state => get(state[COMPANY_SCOPES.companyTariffs].data, 'data.dedicatedPersonalTariff.isMigrated'),
      openPersonalTariffUuid: state => get(state[COMPANY_SCOPES.companyTariffs].data, 'data.openPersonalTariff.tariffUuid'),
      openPersonalIsMigrated: state => get(state[COMPANY_SCOPES.companyTariffs].data, 'data.openPersonalTariff.isMigrated'),
    }),
    modalButtons() {
      return [
        {
          ...CancelButtonAttrs,
          listeners: {
            click: () => this.$emit('closeModal'),
          },
        },
        {
          attributes: {
            loading: this.companyEmployeeTariffStatus.LOADING,
            disabled: isEqual(this.originalInputs, this.inputs) || this.companyEmployeeTariffStatus.LOADING,
          },
          text: 'Save',
          listeners: {
            click: () => this.saveCompanyEmployeeTariff(),
          },
        },
      ];
    },
    isTariffExists() {
      return !!this[`${this.fleet}${EMPLOYEE_INPUT_FORM_KEYS.uuid}`];
    },
  },
  async created() {
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.COMPANY_SCOPES = COMPANY_SCOPES;
    this.EMPLOYEE_INPUT_FORM_KEYS = EMPLOYEE_INPUT_FORM_KEYS;
    await this.getAvailableCompanyTariffs({
      companyUuid: this.company.uuid,
      payload: {
        fleetType: this.fleet,
        profile: PROFILES_TYPES.personal,
        hasSubscription: 0,
      },
    });
    each(FLEETS, fleet => {
      this.inputs[`${fleet}${EMPLOYEE_INPUT_FORM_KEYS.uuid}`] = this[`${fleet}${EMPLOYEE_INPUT_FORM_KEYS.uuid}`] || null;
      this.inputs[`${fleet}${EMPLOYEE_INPUT_FORM_KEYS.isMigrated}`] = this[`${fleet}${EMPLOYEE_INPUT_FORM_KEYS.isMigrated}`] || false;
    });

    this.originalInputs = clone(this.inputs);
  },
  methods: {
    isEqual,
    ...mapActions(DOMAINS_MODEL.crm.company, [
      'putCompanyEmployeePersonalTariffs',
      'getAvailableCompanyTariffs',
    ]),
    getSelectTariffOptions(tariffs) {
      return map(
        tariffs,
        ({
          uuid, id, name, internalName,
        }) => {
          const textId = id ? `${id} ` : '';
          const textName = name || internalName;
          return {
            value: uuid,
            label: `${textId}${textName}`,
          };
        },
      );
    },
    onChangeSelectedTariff({ detail }) {
      this.inputs[`${this.fleet}${EMPLOYEE_INPUT_FORM_KEYS.uuid}`] = detail;
      this.inputs[`${this.fleet}${EMPLOYEE_INPUT_FORM_KEYS.isMigrated}`] = get(find(this.availableCompanyTariffs, { uuid: detail }), 'isMigrated') || false;
    },
    async saveCompanyEmployeeTariff() {
      await this.putCompanyEmployeePersonalTariffs({
        companyUuid: this.company.uuid,
        payload: this.inputs,
      });

      if (!this.companyEmployeeTariffStatus.ERROR) {
        this.$emit('closeModal');
        this.onSuccess();
        this.$notify({
          message: 'Personal tariff edited',
          textAction: null,
        });
      }
    },
  },
};
</script>

<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    :buttons="modalButtons"
    :size="SIZES.small"
    data-test-id="company_employee_tariff-form"
    class="CompanyEmployeeTariffModal"
    title="Edit employee personal tariff"
    v-on="$listeners"
  >
    <template #alerts>
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.crm.company"
        :scope="COMPANY_SCOPES.employeeTariffForm"
        action="edit personal tariff"
      />
    </template>
    <template #body>
      <p class="emobg-body-1 mb-4">
        Any tariff change will apply only for new employees that register with Ubeeqo.
        {{ isTariffExists ? 'Existing employees will remain with the same tariff.' : '' }}
      </p>
      <h3 class="emobg-font-weight-semibold emobg-font-default mb-4">
        Personal use of {{ fleet }}
      </h3>
      <div class="mb-4">
        <ui-skeleton
          v-if="availableCompanyTariffsStatus.LOADING"
          data-test-id="select-skeleton"
        />
        <ui-select
          v-else
          :value="inputs[`${fleet}${EMPLOYEE_INPUT_FORM_KEYS.uuid}`]"
          :options.prop="getSelectTariffOptions(availableCompanyTariffs)"
          clear
          label="Tariff name"
          placeholder="Select a tariff"
          name="tariffUuid"
          class="w-100"
          @selectoption="onChangeSelectedTariff"
        />
      </div>
    </template>
  </GenericModalComponent>
</template>
