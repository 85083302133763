import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  reformatDateTime,
  sentenceCase,
} from '@emobg/web-utils';
import { COLORS, GRAYSCALE } from '@emobg/vue-base';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';

export const FLEETS = {
  open: 'open',
  dedicated: 'dedicated',
};

export const COMPANY_TARIFF_SCHEMA = ({ showStatus = true, operatorTimezone }) => {
  const schema = [
    {
      header: 'Name',
      template: 'tariffName',
      minWidth: 150,
    },
    {
      header: 'Start date',
      template: ({ start }) => reformatDateTime(start, DATE_FORMAT.dob, operatorTimezone),
      minWidth: 150,
    },
    {
      header: 'End date',
      template: ({ end }) => end ? reformatDateTime(end, DATE_FORMAT.dob, operatorTimezone) : FALLBACK_MESSAGE.dash,
      minWidth: 150,
    },
    {
      header: 'Fleet',
      template: ({ fleet }) => sentenceCase(fleet),
    },
    {
      header: 'Periodicity',
      template: ({ period }) => period ? sentenceCase(period) : FALLBACK_MESSAGE.dash,
    },
  ];

  if (showStatus) {
    schema.push({
      header: 'Status',
      component: BadgeComponent,
      props: ({ isFuture }) => ({
        text: isFuture ? 'Next tariff' : 'Active tariff',
        color: isFuture ? GRAYSCALE.ground : COLORS.success,
      }),
      minWidth: 135,
    });
  }

  return schema;
};

export const EMPLOYEE_INPUT_FORM_KEYS = {
  uuid: 'PersonalTariffUuid',
  isMigrated: 'PersonalIsMigrated',
};
