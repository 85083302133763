var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "CompanyTariffsView",
      attrs: { "data-test-id": "company_tariffs-view" },
    },
    [
      _c("h1", { staticClass: "mb-2" }, [_vm._v(" Tariffs ")]),
      _c(
        "div",
        { staticClass: "position-relative" },
        [
          _vm.isLoading
            ? _c("ui-loader", {
                attrs: { absolute: "", label: "Loading company tariffs..." },
              })
            : _vm._e(),
          _vm.showEmployeeTariffSection
            ? _c(
                "ui-card",
                {
                  staticClass: "d-block mb-4",
                  attrs: { header: "Employee personal tariffs" },
                },
                [
                  _c(
                    "div",
                    _vm._l(
                      _vm.employeeTariffs,
                      function (tariff, fleet, index) {
                        return _c("div", { key: fleet }, [
                          _c(
                            "h3",
                            {
                              staticClass:
                                "emobg-font-weight-semibold emobg-font-default mb-4",
                            },
                            [_vm._v(" Personal use of " + _vm._s(fleet) + " ")]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-between" },
                            [
                              _c("ContentCellComponent", {
                                attrs: {
                                  value: tariff
                                    ? `${tariff.tariffName}`
                                    : _vm.FALLBACK_MESSAGE.dash,
                                  label: "Tariff name",
                                },
                              }),
                              _c("ui-icon", {
                                staticClass: "mt-2 ml-1 cursor-pointer",
                                attrs: {
                                  icon: _vm.ICONS.edit,
                                  "data-test-id": `edit_${fleet}_tariff-button`,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openEmployeePersonalTariffModal(
                                      fleet,
                                      tariff
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.keys(_vm.employeeTariffs).length > 1 &&
                          index === 0
                            ? _c("hr", {
                                staticClass:
                                  "emobg-border-top-1 emobg-border-color-ground-light my-3",
                              })
                            : _vm._e(),
                        ])
                      }
                    ),
                    0
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "ui-card",
            [
              _c(
                "div",
                { staticClass: "d-flex mb-2" },
                [
                  _c(
                    "h2",
                    { staticClass: "flex-fill emobg-font-weight-semibold" },
                    [_vm._v(" Current company tariffs ")]
                  ),
                  _vm.company.hasDedicatedFleet
                    ? _c(
                        "ui-dropdown",
                        {
                          attrs: {
                            color: _vm.COLORS.primary,
                            "text-color": _vm.GRAYSCALE.white,
                            face: _vm.FACES.fill,
                            disabled: _vm.isAddNewTariffButtonDisabled,
                            arrow: "",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "pl-3 emobg-font-weight-semibold",
                              attrs: { slot: "trigger" },
                              slot: "trigger",
                            },
                            [_vm._v(" Add company tariff ")]
                          ),
                          _c("ui-dropdown-actions", {
                            attrs: { slot: "content" },
                            domProps: { actions: _vm.addTariffOptions },
                            slot: "content",
                          }),
                        ],
                        1
                      )
                    : _c(
                        "ui-button",
                        {
                          attrs: { disabled: _vm.isOpenFleetButtonDisabled },
                          on: {
                            clickbutton: function ($event) {
                              return _vm.openAddTariffModal(_vm.FLEETS.open)
                            },
                          },
                        },
                        [_vm._v(" Add company tariff ")]
                      ),
                ],
                1
              ),
              _c("TableComponent", {
                attrs: {
                  data: _vm.currentTariffs,
                  "row-actions": _vm.COMPANY_TARIFF_ACTIONS,
                  schema: _vm.COMPANY_TARIFF_SCHEMA,
                  "empty-label": "You haven't added any tariffs yet",
                  "data-test-id": "current_tariffs-table",
                },
              }),
            ],
            1
          ),
          _c(
            "ui-card",
            { staticClass: "d-block w-100 mt-4" },
            [
              _c("h2", { staticClass: "emobg-font-weight-semibold mb-2" }, [
                _vm._v(" Previous company tariffs "),
              ]),
              _c("TableComponent", {
                attrs: {
                  data: _vm.pastTariffs,
                  schema: _vm.PAST_COMPANY_TARIFF_SCHEMA,
                  "empty-label": "No past tariffs to show",
                  "data-test-id": "past_tariffs-table",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isTariffModalOpened
        ? _c("CompanyTariffsModal", {
            attrs: {
              "tariff-to-edit": _vm.tariffToEdit,
              "current-tariff": _vm.find(_vm.currentTariffs, {
                fleet: _vm.tariffEditFleet,
                isFuture: false,
              }),
              "tariff-edit-fleet": _vm.tariffEditFleet,
              "on-success": _vm.initialLoad,
              "data-test-id": "tariff-modal",
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm.isDeleteTariffModalOpened
        ? _c("DeleteCompanyTariffModal", {
            attrs: {
              tariff: _vm.tariffToEdit,
              "on-success": _vm.initialLoad,
              "data-test-id": "tariff_delete-modal",
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm.isEmployeePersonalTariffModalOpen
        ? _c("CompanyEmployeeTariffModal", {
            attrs: {
              fleet: _vm.employeeFleetToEdit,
              "on-success": _vm.initialLoad,
              "data-test-id": "employee_tariff-modal",
            },
            on: { closeModal: _vm.closeEmployeePersonalTariffModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }