var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "CompanyEmployeeTariffModal",
        attrs: {
          header: { isClosable: true },
          buttons: _vm.modalButtons,
          size: _vm.SIZES.small,
          "data-test-id": "company_employee_tariff-form",
          title: "Edit employee personal tariff",
        },
        scopedSlots: _vm._u([
          {
            key: "alerts",
            fn: function () {
              return [
                _c("StoreNotificationComponent", {
                  attrs: {
                    "store-domain": _vm.DOMAINS_MODEL.crm.company,
                    scope: _vm.COMPANY_SCOPES.employeeTariffForm,
                    action: "edit personal tariff",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("p", { staticClass: "emobg-body-1 mb-4" }, [
                  _vm._v(
                    " Any tariff change will apply only for new employees that register with Ubeeqo. " +
                      _vm._s(
                        _vm.isTariffExists
                          ? "Existing employees will remain with the same tariff."
                          : ""
                      ) +
                      " "
                  ),
                ]),
                _c(
                  "h3",
                  {
                    staticClass:
                      "emobg-font-weight-semibold emobg-font-default mb-4",
                  },
                  [_vm._v(" Personal use of " + _vm._s(_vm.fleet) + " ")]
                ),
                _c(
                  "div",
                  { staticClass: "mb-4" },
                  [
                    _vm.availableCompanyTariffsStatus.LOADING
                      ? _c("ui-skeleton", {
                          attrs: { "data-test-id": "select-skeleton" },
                        })
                      : _c("ui-select", {
                          staticClass: "w-100",
                          attrs: {
                            value:
                              _vm.inputs[
                                `${_vm.fleet}${_vm.EMPLOYEE_INPUT_FORM_KEYS.uuid}`
                              ],
                            clear: "",
                            label: "Tariff name",
                            placeholder: "Select a tariff",
                            name: "tariffUuid",
                          },
                          domProps: {
                            options: _vm.getSelectTariffOptions(
                              _vm.availableCompanyTariffs
                            ),
                          },
                          on: { selectoption: _vm.onChangeSelectedTariff },
                        }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }