<script>
import find from 'lodash/find';
import get from 'lodash/get';
import clone from 'lodash/clone';
import isEqual from 'lodash/isEqual';
import moment from 'moment/moment';
import map from 'lodash/map';

import { mapActions, mapMutations, mapState } from 'vuex';
import { watch } from 'vue';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  DATE_FORMAT,
  DATE_FORMAT_KEYS,
  reformatDateTime,
  sentenceCase,
} from '@emobg/web-utils';
import { MuiDatePicker } from '@emobg/vue-base';
import {
  MuiSelect,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { COMPANY_SCOPES } from '@domains/CRM/Companies/store/CompanyModule';
import { PROFILES_TYPES } from '@domains/CRM/Users/constants/userStatus';
import {
  CancelButton,
  ContentCellComponent,
  GenericModalComponent,
  StoreNotificationComponent,
} from '@/components';
import { DATE_INPUT_VALIDATIONS } from '@/utils';
import { NOTIFICATION_TYPES } from '@/constants/notifications';
import { FLEETS } from './const/CompanyTariffs';

export default {
  name: 'CompanyTariffsModal',
  components: {
    CancelButton,
    ContentCellComponent,
    GenericModalComponent,
    MuiSelect,
    MuiDatePicker,
    MuiValidationWrapper,
    StoreNotificationComponent,
  },
  directives: {
    Validate,
  },
  props: {
    currentTariff: {
      type: Object,
      default: null,
    },
    tariffToEdit: {
      type: Object,
      default: null,
    },
    tariffEditFleet: {
      type: String,
      default: '',
    },
    onSuccess: {
      type: Function,
      default: () => {
      },
    },
  },
  data() {
    return {
      isFormValid: true,
      originalInputs: null,
      quotas: [],
      inputs: {
        tariffUuid: '',
        startDate: '',
        endDate: '',
        period: '',
      },
      tariffSelected: null,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state[COMPANY_SCOPES.company].data,
      availableCompanyTariffs: state => get(state[COMPANY_SCOPES.availableCompanyTariffs].data, 'data') || [],
      availableCompanyTariffsStatus: state => state[COMPANY_SCOPES.availableCompanyTariffs].STATUS,
      availableCompanyTariffPeriods: state => get(state[COMPANY_SCOPES.availableCompanyTariffPeriods].data, 'data') || [],
      availableCompanyTariffPeriodsStatus: state => state[COMPANY_SCOPES.availableCompanyTariffPeriods].STATUS,
      companyTariffFormStatus: state => state[COMPANY_SCOPES.companyTariffForm].STATUS,
    }),
    isEdit() {
      return !!this.tariffToEdit;
    },
    minStartDate() {
      let minDate = moment();

      if (this.currentTariff) {
        const currentEnd = get(this.currentTariff, 'end');

        if (currentEnd) {
          minDate = moment(currentEnd).add(1, 'day');
        } else {
          const currentStart = moment(get(this.currentTariff, 'start'));
          minDate = currentStart.isAfter(minDate) ? currentStart : minDate;
        }
      }

      return minDate;
    },
    minimumStartForEndDate() {
      const start = moment(this.inputs.startDate).startOf('day');
      const today = moment().startOf('day');
      return this.inputs.startDate && start.isBefore(today) ? today : moment(start).add(1, 'day');
    },
    hasTariffQuotas() {
      return !!this.availableCompanyTariffPeriods.length;
    },
    hasAnActiveTariff() {
      return get(this.currentTariff, 'end');
    },
    validStartRange() {
      const currentEndDate = get(this.currentTariff, 'end');
      return {
        start: currentEndDate ? moment(currentEndDate).add(1, 'days') : moment(),
        end: moment(this.minStartDate).year(2100).endOf('year'),
      };
    },
    postCompanyTariffRequest() {
      return get(this.tariffSelected, 'isMigrated', false)
        ? this.postCompanyTariff
        : this.postLegacyCompanyTariff;
    },
    postTariffPayload() {
      const company = get(this.tariffSelected, 'isMigrated', false) ? { companyUuid: this.company.uuid } : { companyId: this.company.id };
      const tariff = get(this.tariffSelected, 'isMigrated', false) ? { tariffUuid: this.inputs.tariffUuid } : { tariffId: get(this.tariffSelected, 'id') };
      return {
        ...company,
        payload: {
          ...tariff,
          startDate: reformatDateTime(this.inputs.startDate, DATE_FORMAT.date),
          endDate: reformatDateTime(this.inputs.endDate, DATE_FORMAT.date),
          fleet: this.tariffEditFleet,
          period: this.inputs.period,
        },
      };
    },
  },
  async created() {
    this.FLEETS = FLEETS;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.COMPANY_SCOPES = COMPANY_SCOPES;
    this.DATE_FORMAT = DATE_FORMAT;
    this.DATE_FORMAT_KEYS = DATE_FORMAT_KEYS;
    this.DATE_INPUT_VALIDATIONS = DATE_INPUT_VALIDATIONS;
    this.setData({
      scope: COMPANY_SCOPES.availableCompanyTariffPeriods,
      value: [],
    });

    if (this.isEdit) {
      this.inputs.tariffUuid = this.tariffToEdit.tariffUuid;
      this.inputs.startDate = this.tariffToEdit.start ? moment(this.tariffToEdit.start) : '';
      this.inputs.endDate = this.tariffToEdit.end ? moment(this.tariffToEdit.end) : '';
      this.inputs.period = this.tariffToEdit.period;
      await this.getTariffPeriods(this.tariffToEdit.tariffUuid);
    } else {
      this.inputs.startDate = get(this.currentTariff, 'end') ? moment(get(this.currentTariff, 'end')).add(1, 'days') : null;
      await this.getAvailableCompanyTariffs({
        companyUuid: this.company.uuid,
        payload: {
          fleetType: this.tariffEditFleet,
          profile: PROFILES_TYPES.business,
        },
      });
    }

    this.originalInputs = clone(this.inputs);

    watch(
      () => this.inputs.tariffUuid,
      async newTariffUuid => {
        this.inputs.period = null;

        if (newTariffUuid) {
          await this.getTariffPeriods(newTariffUuid);
        } else {
          this.setData({
            scope: COMPANY_SCOPES.availableCompanyTariffPeriods,
            value: [],
          });
        }
      },
    );
  },
  methods: {
    find,
    get,
    isEqual,
    map,
    reformatDateTime,
    sentenceCase,
    ...mapMutations(DOMAINS_MODEL.crm.company, ['setData']),
    ...mapActions(DOMAINS_MODEL.crm.company, [
      'postCompanyTariff',
      'putCompanyTariff',
      'postLegacyCompanyTariff',
      'getAvailableCompanyTariffs',
      'getAvailableCompanyTariffPeriods',
    ]),
    setStartDate(date) {
      this.inputs.startDate = date;
      if (this.inputs.startDate.isAfter(this.inputs.endDate)) {
        this.inputs.endDate = moment(this.startInput).add(1, 'day');
      }
    },
    async companyTariffRequest() {
      const requestUrl = this.isEdit ? this.putCompanyTariff : this.postCompanyTariffRequest;
      const putPayload = {
        companyTariffUuid: get(this, 'tariffToEdit.uuid'),
        payload: {
          endDate: reformatDateTime(this.inputs.endDate, DATE_FORMAT.date),
          period: this.inputs.period,
        },
      };

      const requestPayload = this.isEdit ? putPayload : this.postTariffPayload;

      await requestUrl(requestPayload);

      if (!this.companyTariffFormStatus.ERROR) {
        this.$notify({
          message: `Tariff successfully <span class="emobg-font-weight-semibold">${this.isEdit ? 'edited' : 'added'}</span>`,
          type: NOTIFICATION_TYPES.success,
          textAction: '',
        });
        this.onSuccess();
        this.$emit('closeModal');
      }
    },
    isBeforeCurrentTariff(date) {
      const message = this.currentTariff
        ? `New tariff must start after current one:
          ${reformatDateTime(get(this.currentTariff, 'end'), DATE_FORMAT.dob) || this.minStartDate.format(DATE_FORMAT.dob)}`
        : 'New tariff must start today minimum';
      return {
        isValid: date.isSameOrAfter(this.minStartDate),
        message,
      };
    },
    async getTariffPeriods(tariffUuid) {
      const tariff = this.isEdit ? this.tariffToEdit : this.tariffSelected;
      await this.getAvailableCompanyTariffPeriods({
        tariffUuid,
        data: {
          profile: PROFILES_TYPES.business,
          isMigrated: get(tariff, 'isMigrated', false),
        },
      });
    },
    getAvailableCompanyTariffOptions() {
      return map(this.availableCompanyTariffs, tariff => {
        const { id, internalName, name } = tariff;
        const tariffName = name || internalName;
        return {
          label: `${id ? `${id} ` : ''}${tariffName}`,
          value: tariff.uuid,
        };
      });
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :size="SIZES.small"
    :header="{ isClosable: true }"
    :title="`${isEdit ? 'Edit' : 'Add new'} tariff`"
    data-test-id="company-tariff-form"
    class="CompanyTariffModal"
    v-on="$listeners"
  >
    <template #alerts>
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.crm.company"
        :scope="COMPANY_SCOPES.companyTariffForm"
        :action="isEdit ? 'Save' : 'Add'"
      />
    </template>
    <template #body>
      <MuiValidationWrapper
        :extra-conditions="[!(isEqual(originalInputs, inputs))]"
        @areAllValid="isValid => isFormValid = isValid"
      >
        <div
          v-if="!isEdit && hasAnActiveTariff"
          class="mb-4"
        >
          You have another tariff, will finish on {{ reformatDateTime(get(currentTariff, 'end'), DATE_FORMAT.dob) }},
          your new tariff must start after this date.
        </div>
        <div class="mb-4">
          <ContentCellComponent
            :value="isEdit ? sentenceCase(get(tariffToEdit, 'fleet')) : sentenceCase(tariffEditFleet)"
            label="Fleet"
          />
        </div>
        <ContentCellComponent
          v-if="isEdit"
          :value="get(tariffToEdit, 'tariffName')"
          label="Tariff name"
          class="mb-4"
        />
        <div
          v-else
          class="mb-4"
        >
          <ui-skeleton
            v-if="availableCompanyTariffsStatus.LOADING"
            data-test-id="select-skeleton"
          />
          <MuiSelect
            v-else
            v-model="inputs.tariffUuid"
            v-validate="{
              isRequired: true,
            }"
            :options="getAvailableCompanyTariffOptions()"
            label="Tariff name*"
            class="w-100"
            name="tariffName"
            placeholder="Select a tariff"
            data-test-id="tariff-select"
            @change="tariffUuid => tariffSelected = find(availableCompanyTariffs, { uuid: tariffUuid })"
          />
        </div>
        <div
          v-if="hasTariffQuotas"
          class="mb-4"
        >
          <MuiSelect
            v-model="inputs.period"
            v-validate="{
              isRequired: true,
            }"
            :options="map(availableCompanyTariffPeriods, period => ({
              label: `${period.period} - ${period.fee}`,
              value: period.period,
            }))"
            label="Periodicity*"
            name="periodicity"
            class="w-100 text-capitalize"
            placeholder="Select"
            data-test-id="periodicity-select"
          />
        </div>
        <ContentCellComponent
          v-if="isEdit"
          :value="reformatDateTime(inputs.startDate, DATE_FORMAT.dob)"
          label="Start date"
          class="mb-4"
        />
        <div
          v-else
          class="mb-4"
        >
          <MuiDatePicker
            v-model="inputs.startDate"
            v-validate="{
              isRequired: true,
              isValidDate: DATE_INPUT_VALIDATIONS.validDate,
              isBeforeCurrentTariff,
            }"
            :valid-date-range="validStartRange"
            :date-format-key="DATE_FORMAT_KEYS.dob"
            :placeholder="DATE_FORMAT.dob"
            skip-time-step
            data-test-id="start_date-select"
            label="Start date*"
            name="startDate"
            class="w-100"
            @update:date="setStartDate"
          />
        </div>

        <MuiDatePicker
          v-model="inputs.endDate"
          v-validate="{
            isRequired: true,
            isValidDate: DATE_INPUT_VALIDATIONS.validDate,
          }"
          :valid-date-range="{
            start: minimumStartForEndDate,
            end: moment(minStartDate).year(2100).endOf('year'),
          }"
          :date-format-key="DATE_FORMAT_KEYS.dob"
          :placeholder="DATE_FORMAT.dob"
          skip-time-step
          data-test-id="end_date-select"
          label="End date*"
          name="endDate"
          class="w-100"
          @update:date="newDate => inputs.endDate = newDate"
        />
      </MuiValidationWrapper>
    </template>
    <template #footer>
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <CancelButton @click="$emit('closeModal')" />
        <ui-button
          :disabled="!isFormValid || companyTariffFormStatus.LOADING || availableCompanyTariffPeriodsStatus.LOADING || availableCompanyTariffsStatus.LOADING"
          :loading="companyTariffFormStatus.LOADING"
          class="wmin-initial"
          @clickbutton="companyTariffRequest"
        >
          {{ isEdit ? 'Save' : 'Add' }}
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
