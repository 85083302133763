<script>
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { COMPANY_SCOPES } from '@domains/CRM/Companies/store/CompanyModule';
import {
  CancelButton,
  DeleteButton,
  DeleteModalBodyComponent,
  GenericModalComponent,
} from '@/components';

export default {
  components: {
    CancelButton,
    DeleteModalBodyComponent,
    GenericModalComponent,
    DeleteButton,
  },
  props: {
    tariff: {
      type: Object,
      required: true,
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state[COMPANY_SCOPES.company].data,
      deleteCompanyTariffStatus: state => state[COMPANY_SCOPES.deleteCompanyTariff].STATUS,
    }),
  },
  methods: {
    get,
    ...mapActions(DOMAINS_MODEL.crm.company, [
      'deleteCompanyTariff',
    ]),
    async removeCompanyTariff() {
      await this.deleteCompanyTariff({ companyTariffUuid: this.tariff.uuid });
      if (!this.deleteCompanyTariffStatus.ERROR) {
        this.$notify({
          message: 'Tariff successfully <span class="emobg-font-weight-semibold">deleted</span>',
          textAction: '',
        });
        this.onSuccess();
      }
      this.$emit('closeModal');
    },

  },
};
</script>
<template>
  <GenericModalComponent
    :header="null"
    class="DeleteCompanyTariffModal"
    v-on="$listeners"
  >
    <template #body>
      <DeleteModalBodyComponent title="Are you sure you want to delete this tariff?" />
    </template>
    <template #footer>
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('closeModal')"
      />
      <DeleteButton
        :loading="deleteCompanyTariffStatus.LOADING"
        data-test-id="delete-button"
        @click="removeCompanyTariff"
      />
    </template>
  </GenericModalComponent>
</template>
