<script>
import each from 'lodash/each';
import find from 'lodash/find';
import get from 'lodash/get';
import keys from 'lodash/keys';
import map from 'lodash/map';
import head from 'lodash/head';
import some from 'lodash/some';
import values from 'lodash/values';
import isUndefined from 'lodash/isUndefined';
import { mapActions, mapGetters, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import CompanyTariffsModal from '@domains/CRM/Companies/Detail/Tariffs/CompanyTariffsModal';
import DeleteCompanyTariffModal from '@domains/CRM/Companies/Detail/Tariffs/DeleteCompanyTariffModal.vue';
import { ContentCellComponent, TableComponent } from '@/components';
import { COMPANY_TARIFF_SCHEMA, FLEETS } from './const/CompanyTariffs';
import { COMPANY_SCOPES } from '../../store/CompanyModule';
import CompanyEmployeeTariffModal from './CompanyEmployeeTariffModal';

export default {
  name: 'CompanyTariffsView',
  components: {
    CompanyEmployeeTariffModal,
    ContentCellComponent,
    CompanyTariffsModal,
    DeleteCompanyTariffModal,
    TableComponent,
  },
  data() {
    return {
      isLoading: true,
      isDeleteTariffModalOpened: false,
      isTariffModalOpened: false,
      tariffEditFleet: FLEETS.open,
      tariffToEdit: null,
      employeeTariffs: {},
      isEmployeePersonalTariffModalOpen: false,
      employeeFleetToEdit: undefined,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state[COMPANY_SCOPES.company].data,
      companyTariffs: state => get(state[COMPANY_SCOPES.companyTariffs].data, 'data.companyTariffs'),
      dedicatedPersonalTariff: state => get(state[COMPANY_SCOPES.companyTariffs].data, 'data.dedicatedPersonalTariff'),
      openPersonalTariff: state => get(state[COMPANY_SCOPES.companyTariffs].data, 'data.openPersonalTariff'),
    }),
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'operatorTimezone',
    ]),
    currentTariffs() {
      return [
        ...map(get(this.companyTariffs, 'future', []), item => ({ ...item, isFuture: true })),
        ...map(get(this.companyTariffs, 'current', []), item => ({ ...item, isFuture: false })),
      ];
    },
    pastTariffs() {
      return values(get(this.companyTariffs, 'past', []));
    },
    addTariffOptions() {
      return [
        {
          label: 'Open fleet',
          disabled: this.isOpenFleetButtonDisabled,
          action: () => {
            this.openAddTariffModal(FLEETS.open);
          },
        },
        {
          label: 'Dedicated fleet',
          disabled: this.isDedicatedFleetButtonDisabled,
          action: () => {
            this.openAddTariffModal(FLEETS.dedicated);
          },
        },
      ];
    },
    isOpenFleetButtonDisabled() {
      const { end } = find(this.currentTariffs, { fleet: FLEETS.open, isFuture: false }) || {};
      return this.hasFutureTariff(FLEETS.open) || (!end && !isUndefined(end));
    },
    isDedicatedFleetButtonDisabled() {
      const { end } = find(this.currentTariffs, { fleet: FLEETS.dedicated, isFuture: false }) || {};
      return this.hasFutureTariff(FLEETS.dedicated) || (!end && !isUndefined(end));
    },
    isAddNewTariffButtonDisabled() {
      return this.isOpenFleetButtonDisabled && this.isDedicatedFleetButtonDisabled;
    },
    showEmployeeTariffSection() {
      return this.company.personalDedicatedUse || this.company.personalOpenUse;
    },
  },
  async created() {
    this.FLEETS = FLEETS;
    this.COMPANY_TARIFF_SCHEMA = COMPANY_TARIFF_SCHEMA({
      operatorTimezone: this.operatorTimezone,
    });
    this.PAST_COMPANY_TARIFF_SCHEMA = COMPANY_TARIFF_SCHEMA({
      operatorTimezone: this.operatorTimezone,
      showStatus: false,
    });
    this.COMPANY_TARIFF_ACTIONS = [
      {
        label: 'Edit company tariff',
        action: this.openEditTariffModal,
        hideAction: ({ editable }) => !editable,
      },
      {
        label: 'Delete company tariff',
        action: this.openDeleteTariffModal,
        labelClass: 'emobg-color-danger',
        hideAction: ({ removable }) => !removable,
      },
    ];
    this.ALLOWED_FLEET_MAPPING = {
      [FLEETS.open]: 'personalOpenUse',
      [FLEETS.dedicated]: 'personalDedicatedUse',
    };
    await this.initialLoad();
  },
  methods: {
    get,
    head,
    keys,
    find,

    ...mapActions(DOMAINS_MODEL.crm.company, [
      'getCompanyTariffs',
    ]),
    async initialLoad() {
      this.isLoading = true;
      await this.getCompanyTariffs({ companyUuid: get(this.company, 'uuid') });
      this.employeeTariffs = {};
      each(FLEETS, fleet => {
        if (this.company[this.ALLOWED_FLEET_MAPPING[fleet]]) {
          this.$set(this.employeeTariffs, fleet, this[`${fleet}PersonalTariff`]);
        }
      });
      this.isLoading = false;
    },
    openAddTariffModal(fleet = FLEETS.open) {
      this.isTariffModalOpened = true;
      this.tariffEditFleet = fleet;
    },
    openEditTariffModal(tariff) {
      this.isTariffModalOpened = true;
      this.tariffToEdit = tariff;
    },
    openDeleteTariffModal(tariff) {
      this.isDeleteTariffModalOpened = true;
      this.tariffToEdit = tariff;
    },
    closeModal() {
      this.isDeleteTariffModalOpened = false;
      this.isTariffModalOpened = false;
      this.tariffToEdit = null;
    },
    hasFutureTariff(fleet) {
      return some(this.currentTariffs, { fleet, isFuture: true });
    },
    openEmployeePersonalTariffModal(fleet) {
      this.isEmployeePersonalTariffModalOpen = true;
      this.employeeFleetToEdit = fleet;
    },
    closeEmployeePersonalTariffModal() {
      this.isEmployeePersonalTariffModalOpen = false;
      this.employeeFleetToEdit = undefined;
    },
  },
};
</script>

<template>
  <div
    class="CompanyTariffsView"
    data-test-id="company_tariffs-view"
  >
    <h1 class="mb-2">
      Tariffs
    </h1>
    <div class="position-relative">
      <ui-loader
        v-if="isLoading"
        absolute
        label="Loading company tariffs..."
      />
      <ui-card
        v-if="showEmployeeTariffSection"
        header="Employee personal tariffs"
        class="d-block mb-4"
      >
        <div>
          <div
            v-for="(tariff, fleet, index) in employeeTariffs"
            :key="fleet"
          >
            <h3 class="emobg-font-weight-semibold emobg-font-default mb-4">
              Personal use of {{ fleet }}
            </h3>
            <div class="d-flex justify-content-between">
              <ContentCellComponent
                :value="tariff ? `${tariff.tariffName}` : FALLBACK_MESSAGE.dash"
                label="Tariff name"
              />
              <ui-icon
                :icon="ICONS.edit"
                :data-test-id="`edit_${fleet}_tariff-button`"
                class="mt-2 ml-1 cursor-pointer"
                @click="openEmployeePersonalTariffModal(fleet, tariff)"
              />
            </div>
            <hr
              v-if="keys(employeeTariffs).length > 1 && index === 0"
              class="emobg-border-top-1 emobg-border-color-ground-light my-3"
            >
          </div>
        </div>
      </ui-card>
      <ui-card>
        <div class="d-flex mb-2">
          <h2 class="flex-fill emobg-font-weight-semibold">
            Current company tariffs
          </h2>
          <ui-dropdown
            v-if="company.hasDedicatedFleet"
            :color="COLORS.primary"
            :text-color="GRAYSCALE.white"
            :face="FACES.fill"
            :disabled="isAddNewTariffButtonDisabled"
            arrow
          >
            <p
              slot="trigger"
              class="pl-3 emobg-font-weight-semibold"
            >
              Add company tariff
            </p>
            <ui-dropdown-actions
              slot="content"
              :actions.prop="addTariffOptions"
            />
          </ui-dropdown>
          <ui-button
            v-else
            :disabled="isOpenFleetButtonDisabled"
            @clickbutton="openAddTariffModal(FLEETS.open)"
          >
            Add company tariff
          </ui-button>
        </div>
        <TableComponent
          :data="currentTariffs"
          :row-actions="COMPANY_TARIFF_ACTIONS"
          :schema="COMPANY_TARIFF_SCHEMA"
          empty-label="You haven't added any tariffs yet"
          data-test-id="current_tariffs-table"
        />
      </ui-card>
      <ui-card class="d-block w-100 mt-4">
        <h2 class="emobg-font-weight-semibold mb-2">
          Previous company tariffs
        </h2>
        <TableComponent
          :data="pastTariffs"
          :schema="PAST_COMPANY_TARIFF_SCHEMA"
          empty-label="No past tariffs to show"
          data-test-id="past_tariffs-table"
        />
      </ui-card>
    </div>
    <CompanyTariffsModal
      v-if="isTariffModalOpened"
      :tariff-to-edit="tariffToEdit"
      :current-tariff="find(currentTariffs, { fleet: tariffEditFleet, isFuture: false }) "
      :tariff-edit-fleet="tariffEditFleet"
      :on-success="initialLoad"
      data-test-id="tariff-modal"
      @closeModal="closeModal"
    />
    <DeleteCompanyTariffModal
      v-if="isDeleteTariffModalOpened"
      :tariff="tariffToEdit"
      :on-success="initialLoad"
      data-test-id="tariff_delete-modal"
      @closeModal="closeModal"
    />
    <CompanyEmployeeTariffModal
      v-if="isEmployeePersonalTariffModalOpen"
      :fleet="employeeFleetToEdit"
      :on-success="initialLoad"
      data-test-id="employee_tariff-modal"
      @closeModal="closeEmployeePersonalTariffModal"
    />
  </div>
</template>
